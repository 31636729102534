<template>
  <div v-if="isLoading" class="loading-spinner fadeOut">
    <SvgLoader></SvgLoader>
  </div>
  <div v-else class="home fadeIn">
    <Navbar></Navbar> 
  <router-view />
  <FooterMain></FooterMain>
  <BackToTop></BackToTop>
</div>
</template>

<script>
import SvgLoader from './components/Loader/SvgLoader.vue';
import Navbar from './components/Header/NavBar.vue';
import FooterMain from './components/Footer/FooterMain.vue';
import BackToTop from './components/Main/BackToTop.vue';
export default {
  name: 'App',

  components: {
    SvgLoader,
    Navbar,
    FooterMain,
    BackToTop
  },

  data(){
    return {
      isLoading: true,
    }
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2500);
  },
}
</script>

