<template>
    <div class="email-subscribe">
      <input type="email" placeholder="Email" class="email-input" />
      <button class="subscribe-button">Ξεκινήστε</button>
    </div>
  </template>

  <script>
  export default{
    name: "InputButton"
  }
</script>
  
  <style scoped>
  .email-subscribe {
    display: flex;
    align-items: center;
    background-color: #1a2a3a;
    border: 1px solid #3a4a5a;
    border-radius: 30px;
    padding: 0.5rem;
    max-width: 400px;
  }
  
  .email-input {
    flex: 1;
    padding: 0.75rem 1rem;
    background-color: transparent;
    border: none;
    color: #b0bec5;
    font-size: 1rem;
    outline: none;
    border-radius: 30px 0 0 30px;
  }
  
  .email-input::placeholder {
    color: #b0bec5;
  }

  
  .subscribe-button {
    padding: 0.5rem 1.5rem;
    background-color: #2f55d4;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .subscribe-button:hover {
    background-color: #FFC947;
    color: #1a2a3a;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  </style>
  